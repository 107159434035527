// Проверка поддержки webp, добавление класса webp или no-webp для HTML

export function isWebp() {
  function testWebP(callback) {
    let webP = new Image();
    webP.onload = webP.onerror = function () {
      callback(webP.height == 2);
    };
    webP.src = "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
  }
  testWebP(function (support) {
    let className = support === true ? "webp" : "no-webp";
    document.documentElement.classList.add(className);
  });
}

// ОТКЛЮЧИТЬ :FOCUS ПО КЛИКУ, НО ПОКАЗЫВАТЬ ПРИ ТАББАНГЕ С КЛАВИАТУРЫ

export function focusTabbing() {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", handleFirstTab);
      window.addEventListener("mousedown", handleMouseDownOnce);
    }
  }
  function handleMouseDownOnce() {
    document.body.classList.remove("user-is-tabbing");
    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
  }
  window.addEventListener("keydown", handleFirstTab);
}

// КНОПКА СКРОЛЛА НАВЕРХ

export function scrollToTop() {
  const scrollBtn = document.querySelector(".scroll-to-top");

  if (scrollBtn) {
    const btnVisibility = () => {
      if (window.scrollY > 400) {
        scrollBtn.style.visibility = "visible";
      } else {
        scrollBtn.style.visibility = "hidden";
      }
    };
    document.addEventListener("scroll", () => {
      btnVisibility();
    });
    scrollBtn.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }
}

// ПЛАВНЫЙ ДРОПДАУН В ВЕРТИКАЛЬНОМ МЕНЮ

export function sidebar() {
  document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll(".submenu-toggle").forEach(function (element) {
      element.addEventListener("click", function (e) {
        let parentEl = element.closest(".nav-item");
        let submenuEl = parentEl.querySelector(".submenu");

        parentEl.classList.toggle("active");

        if (submenuEl) {
          e.preventDefault();
          let mycollapse = new bootstrap.Collapse(submenuEl);

          if (submenuEl.classList.contains("show")) {
            mycollapse.hide();
          } else {
            mycollapse.show();
          }
        }
      });
    });
  });
}

// АККОРДИОН

export function accordion() {
  document.addEventListener("DOMContentLoaded", function () {
    const accordionTitle = document.querySelectorAll(".accordion-title");

    if (accordionTitle.length) {
      accordionTitle.forEach(function (element) {
        element.addEventListener("click", function (e) {
          let parentEl = element.closest(".accordion");
          let submenuEl = parentEl.querySelector(".accordion-body");

          parentEl.classList.toggle("active");

          if (submenuEl) {
            e.preventDefault();
            let mycollapse = new bootstrap.Collapse(submenuEl);

            if (submenuEl.classList.contains("show")) {
              mycollapse.hide();
            } else {
              mycollapse.show();
            }
          }
        });
      });
    }
  });
}

// ПОКАЗАТЬ / СКРЫТЬ СТРОКУ ПОИСКА В ШАПКЕ

export function searchToggle() {
  const searchToggle = document.querySelector(".search-toggle"),
    search = document.querySelector(".search");
  searchToggle.onclick = function (event) {
    event.preventDefault();
    search.classList.toggle("active");
  };
}

// АНИМАЦИЯ КАРТЫ НА ГЛАВНОЙ

export function changeFill() {
  const vectorMap = document.querySelector(".vector-map");

  if (vectorMap) {
    document.addEventListener("DOMContentLoaded", function () {
      var region = document.querySelectorAll(".vector-map svg [id^='svgRegion']");
      var marker = document.querySelectorAll(".vector-map svg [id^='svgMarker']");
      var menu = document.querySelectorAll(".vector-map svg [id^='svgMenu']");
      var i = 0;

      setInterval(function () {
        var currentRegion = region[i];
        var currentMarker = marker[i];
        var currentMenu = menu[i];
        currentRegion.style.fill = "#ffcf00";
        currentMarker.style.opacity = "1";
        currentMenu.style.fill = "#11bb88";

        setTimeout(function () {
          currentRegion.style.fill = "";
          currentMarker.style.opacity = "";
          currentMenu.style.fill = "";
          i = (i + 1) % region.length;
        }, 3000);
      }, 2000);
    });
  }
}

// УВЕЛИЧЕНИЕ ВЫСОТЫ ИНПУТА В ЧАТЕ

export function textareaGrow() {
  const chatTextarea = document.querySelectorAll(".textarea-grow");

  if (chatTextarea.length) {
    chatTextarea.forEach((el) => {
      el.style.height = el.setAttribute("style", "height: " + el.scrollHeight + "px");
      let chatBody = document.querySelector(".chat-body");
      let chatFooter = document.querySelector(".chat-footer");

      el.addEventListener("input", (e) => {
        el.style.height = "auto";
        el.style.height = el.scrollHeight + "px";
        chatBody.style.paddingBottom = chatFooter.scrollHeight + 8 + "px";
      });
    });
  }
}
