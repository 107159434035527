//СМАХИВАТЕЛЬ МОБИЛЬНОГО МЕНЮ

import Xwiper from "xwiper";

const menuOffcanvasEl = document.getElementById("Offcanvas");
const menuOffcanvasFiltersEl = document.getElementById("offcanvasFilters");

if (menuOffcanvasEl) {
  let menuOffcanvas1 = new bootstrap.Offcanvas(menuOffcanvasEl);
  const xwiper = new Xwiper("#Offcanvas");
  xwiper.onSwipeRight(() => {
    menuOffcanvas1.hide();
  });
}

if (menuOffcanvasFiltersEl) {
  let menuOffcanvas2 = new bootstrap.Offcanvas(menuOffcanvasFiltersEl);
  const xwiper = new Xwiper("#offcanvasFilters");
  xwiper.onSwipeLeft(() => {
    menuOffcanvas2.hide();
  });
}

//ПЛАГИН МАСОК ИНПУТОВ

import IMask from "imask";

const phoneInput = document.querySelectorAll(".input-phone");
const textInput = document.querySelectorAll(".input-text");

if (phoneInput.length) {
  phoneInput.forEach(function (el) {
    var phoneMask;

    el.addEventListener("focus", function () {
      phoneMask = IMask(el, {
        mask: "+{7} 000 000 00 00",
        lazy: false,
        placeholderChar: "*",
      });
    });

    el.addEventListener("blur", function () {
      phoneMask.updateOptions({
        mask: "+{7} 000 000 00 00",
        lazy: true,
      });
    });
  });
}

if (textInput.length) {
  textInput.forEach(function (el) {
    var textMask = IMask(el, {
      mask: /^[a-zа-яё\s]+$/iu,
    });
  });
}

// ПРОСМОТРЩИК ФОТО

import { Fancybox } from "@fancyapps/ui";
Fancybox.bind("[data-fancybox]", {
  Toolbar: {
    display: {
      left: ["infobar"],
      right: ["zoomIn", "thumbs", "close"],
    },
  },

  l10n: {
    CLOSE: "Закрыть",
    NEXT: "Дальше",
    PREV: "Назад",
    MODAL: "Вы можете закрыть данное окно, нажав клавишу ESC",
    ERROR: "Что-то пошло не так. Пожалуйста, повторите попытку позже",
    IMAGE_ERROR: "Изображение не найдено",
    ELEMENT_NOT_FOUND: "HTML элемент не найден",
    AJAX_NOT_FOUND: "Ошибка загрузки AJAX : Не найдено",
    AJAX_FORBIDDEN: "Ошибка загрузки AJAX : Запрещено",
    IFRAME_ERROR: "Ошибка загрузки страницы",
    TOGGLE_ZOOM: "Переключить уровень масштаба",
    TOGGLE_THUMBS: "Переключить эскиз",
    TOGGLE_SLIDESHOW: "Переключить презентацию",
    TOGGLE_FULLSCREEN: "Переключить режим полного экрана",
    DOWNLOAD: "Скачать",
    ZOOMIN: "Увеличить",
    ZOOMOUT: "Уменьшить",
  },
});

//ГЛАВНЫЙ СЛАЙДЕР

import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

const mainSlider = document.querySelector(".main-slider");

if (mainSlider) {
  const swiper1 = new Swiper(mainSlider, {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1.1,
    spaceBetween: 12,
    loop: false,
    autoplay: {
      delay: 6000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".sbn-1",
      prevEl: ".sbp-1",
    },
    breakpoints: {
      576: {
        slidesPerView: 1,
        spaceBetween: 12,
        loop: false,
      },

      768: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
  });
}

// СЛАЙДЕР ПОЛЕЗНЫХ ССЫЛОК
/**
 * @type {NodeListOf<HTMLElement>}
 */
const linksSliderContainers = document.querySelectorAll(".links-slider");
for (const sliderContainer of linksSliderContainers) {
  let slider = sliderContainer.querySelector(".swiper");
  let linksSlides = slider.querySelectorAll(".swiper-slide");
  let isLoopCheck1 = linksSlides.length > 6;
  let isLoop1 = isLoopCheck1 ? true : false;
  let left = sliderContainer.querySelector(".sbn-2");
  let right = sliderContainer.querySelector(".sbp-2");

  new Swiper(slider, {
    modules: [Navigation],
    slidesPerView: 2.1,
    spaceBetween: 12,
    loop: isLoop1,
    autoplay: {
      delay: 1000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: left,
      prevEl: right,
    },
    breakpoints: {
      576: {
        slidesPerView: 3,
        spaceBetween: 12,
      },

      768: {
        slidesPerView: 4,
        spaceBetween: 17,
      },

      992: {
        slidesPerView: 5,
        spaceBetween: 17,
      },

      1200: {
        slidesPerView: 5,
        spaceBetween: 24,
      },

      1400: {
        slidesPerView: 6,
        spaceBetween: 24,
      },
    },
  });
}

// СЛАЙДЕР ПАРТНЁРОВ

const partnersSlider = document.querySelector(".partners-slider");

if (partnersSlider) {
  let partnerSlides = document.querySelectorAll(".partners-slider .swiper-slide");
  let isLoopCheck2 = partnerSlides.length > 6;
  let isLoop2 = isLoopCheck2 ? true : false;

  const swiper3 = new Swiper(partnersSlider, {
    modules: [Navigation],
    slidesPerView: 2.1,
    spaceBetween: 12,
    loop: isLoop2,
    autoplay: {
      delay: 1000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".sbn-3",
      prevEl: ".sbp-3",
    },
    breakpoints: {
      576: {
        slidesPerView: 3,
        spaceBetween: 12,
      },

      768: {
        slidesPerView: 4,
        spaceBetween: 17,
      },

      992: {
        slidesPerView: 5,
        spaceBetween: 17,
      },

      1200: {
        slidesPerView: 5,
        spaceBetween: 24,
      },

      1400: {
        slidesPerView: 6,
        spaceBetween: 24,
      },
    },
  });
}

//ПОИСК

// import autoComplete from "@tarekraafat/autocomplete.js";

const searhInputs = document.querySelectorAll(".search-box");

if (searhInputs.length) {
  // searhInputs.forEach(function (element) {
  //   const searchInput = element.querySelector(".form-control");
  //   debugger;
  //   const autoCompleteJS = new autoComplete({
  //     selector: function () {
  //       return searchInput;
  //     },
  //     placeHolder: "Search for Food...",
  //     data: {
  //       src: ["Sauce - Thousand Island", "Wild Boar - Tenderloin", "Goat - Whole Cut"],
  //     },
  //     resultItem: {
  //       highlight: true,
  //     },
  //     resultsList: {
  //       element: (list, data) => {
  //         if (!data.results.length) {
  //           // Create "No Results" message element
  //           const message = document.createElement("div");
  //           // Add class to the created element
  //           message.setAttribute("class", "no_result");
  //           // Add message text content
  //           message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
  //           // Append message element to the results list
  //           list.prepend(message);
  //         }
  //       },
  //       noResults: true,
  //     },
  // resultItem: {
  //   highlight: true,
  // },
  // events: {
  //   input: {
  //     selection: (event) => {
  //       const selection = event.detail.selection.value;
  //       autoCompleteJS.input.value = selection;
  //     },
  //   },
  // },
  // });
  // });
}

//ИМПОРТ ФУНКЦИЙ И ПЛАГИНОВ

import * as flsFunctions from "./functions.js";
flsFunctions.isWebp();
flsFunctions.focusTabbing();
flsFunctions.scrollToTop();
flsFunctions.sidebar();
flsFunctions.accordion();
flsFunctions.searchToggle();
flsFunctions.changeFill();
flsFunctions.textareaGrow();
